import { Suspense } from 'react';
import Spinner from 'Spinner';
import Page from 'Page';
import { put } from 'axios';
import React from "react";
import Form from "../../Newsletters/Newsletter/Form";
import useAxios, {reload} from "use-axios";
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import config from '../../../config';

// edit or delete already existing newsletter
function EditNewsletter ({ match, location }) {

  const newsletterUrl = `${config.API_PATH}/newsletter`;
  const {data} = useAxios(`${newsletterUrl}/${match.params.id}`);

  const created = location.state ? location.state.created : false;

  // save the changes to the newsletter
  const save = async (data) => {
    try {
      await put(`${newsletterUrl}`, data);
      reload(newsletterUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
      <Page>
        <Page.Title>
          <FormattedMessage id={messages.newsletterEdit} />
        </Page.Title>
        <Suspense fallback={<Spinner />}>
          <Form data={data} save={save} created={created}/>
        </Suspense>
      </Page>
  );
}

export default EditNewsletter;

import {post} from "axios";
import {FormattedMessage} from "react-intl";
import Button from "@material-ui/core/Button";
import messages from 'intl/messages.properties';
import React from "react";
import CustomSnackbar from "../../../shared/CustomSnackbar";
import Box from "@material-ui/core/Box";
import config from '../../../config';

const searchUrl = `${config.API_PATH}/search`;

export default function Indexing() {

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const closeSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  const reindexAll = async () => {
    try {
      await post(`${searchUrl}/reindexAll`);
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Box>
      <CustomSnackbar
        open={success}
        onClose={closeSnackbar}
        type="success"
        message={<FormattedMessage id={messages.indexSuccess}/>}
      />
      <CustomSnackbar
        open={error}
        onClose={closeSnackbar}
        type="error"
        message={<FormattedMessage id={messages.indexError}/>}
      />
      <Button variant="contained" color="secondary" onClick={() => reindexAll()}>
        <FormattedMessage id={messages.reindexAll}/>
      </Button>
    </Box>
  )
}

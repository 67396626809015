import {Suspense} from 'react';
import Spinner from 'Spinner';
import Form from './Form';
import RequestErrorBoundary from "../../../shared/RequestErrorBoundary";
import useAxios, {reload} from "use-axios";
import {
  put,
  delete as del,
  post
} from 'axios';
import config from '../../../config';


function EditResource(props) {
  const resourceUrl = `${config.API_PATH}/resource`;

  const {data} = useAxios(`${resourceUrl}/${props.match.params.id}`);

  const created = props.location.state ? props.location.state.created : false;

  const save = async (data) => {
    try {
      await put(`${resourceUrl}`, data);
      reload(resourceUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const copy = async (data) => {
    try {
      const response = await post(`${resourceUrl}/clone/${data.id}`);
      props.history.push(`/resource/${response.data.id}`)
      reload(resourceUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const remove = async data => {
    try {
      await del(`${resourceUrl}/{id}?id=${data.id}`);
      reload(`${resourceUrl}`);
      props.history.push('/resources')
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Suspense fallback={<Spinner/>}>
        <RequestErrorBoundary>
          <Form data={data}
          save={save}
          created={created}
          remove={remove}
          copy={copy}/>
        </RequestErrorBoundary>
      </Suspense>
    </>
  );
}

export default EditResource;

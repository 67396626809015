import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import {
  FormControl,
  TextField,
  List
} from '@material-ui/core';
import {useFormContext} from "react-hook-form";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import {post, delete as del, get} from "axios";
import Authorize from "@postinumero/authorization/Authorize";
import {useDropzone} from "react-dropzone";
import AddFileDialog from "./Dialogs/AddFileDialog";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import RemoveFileDialog from "./Dialogs/RemoveFileDialog";
import {Alert} from "react-bootstrap";
import config from '../../../../config';

const reservationUrl = `${config.API_PATH}/reservation`;

export default function AdditionalInfo(props) {
  const {classes} = props;

  const { register, watch, setValue } = useFormContext();

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length === 0 && acceptedFiles.length > 0) {
      setOpen(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, rejectedFiles } = useDropzone({onDrop, multiple: false, maxSize: 999999});
  const [open, setOpen] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [files, setFiles] = React.useState();
  const [fileToRemove, setFileToRemove] = React.useState();

  useEffect(() => {
    register({ name: "customerDocumentation"});
    register({ name: "mypa"});
    register({ name: "billing"});
    register({ name: "ebilling"});
    register({ name: "restaurantInfo"});
  }, [register]);

  const idWatch = watch("id");
  const customerDocumentationWatch = watch("customerDocumentation");
  const mypaWatch = watch("mypa");
  const billingWatch = watch("billing");
  const eBillingWatch = watch("ebilling");
  const restaurantInfoWatch = watch("restaurantInfo");

  useEffect(() => {
    setFiles([...customerDocumentationWatch]);
  }, [customerDocumentationWatch]);

  const handleChangeTextfield =(event) => {
    setValue(event.target.name, event.target.value);
  }

  const handleAddFiles = (newFiles) => {
    if (!newFiles || newFiles.length < 1) {
      return;
    }
    uploadFiles(newFiles[0]).then((data) => {
      setValue("customerDocumentation", data);
      setFiles(data);
    })
  };

  const uploadFiles = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const response = await post(`${reservationUrl}/${idWatch}/uploadFiles`, formData, config);
      return response.data
    } catch (error) {
      return false;
    }
  };

  const removeFile = async (doc) => {
    await del(`${reservationUrl}/${idWatch}/deleteFile/${doc.id}`);
    let newDocumentation = [...customerDocumentationWatch];
    newDocumentation = newDocumentation.filter(d => d.id !== doc.id);
    setValue("customerDocumentation", newDocumentation);
    setFiles(newDocumentation);
    setFileToRemove(undefined);
    setOpenRemove(false);
  }

  const getFile = (row) => {
    get(`/api/reservation/${idWatch}/file/${row.id}`, {
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers["content-disposition"].match("filename=([^;]*).*$")[1]);
      document.body.appendChild(link);
      link.click();
    });
  };

  return ( <>
      <FormControl className={classes.formControlMultiline}>
        <label className={classes.multilineLabel}>
          <FormattedMessage id={messages.mypa} />
        </label>
        <TextField
          id="mypa"
          name="mypa"
          inputProps={{
            name: 'mypa',
            id: 'mypa'
          }}
          onChange={handleChangeTextfield}
          value={mypaWatch}
          disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
          className={classes.multilineTextField}
          type="string"
          multiline
          variant="filled"
        />
      </FormControl>
      <FormControl className={classes.formControlMultiline}>
        <label className={classes.multilineLabel}>
          <FormattedMessage id={messages.billing} />
        </label>
        <TextField
          id="billing"
          name="billing"
          inputProps={{
            name: 'billing',
            id: 'billing'
          }}
          onChange={handleChangeTextfield}
          value={billingWatch}
          disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
          className={classes.multilineTextField}
          type="string"
          multiline
          variant="filled"
        />
      </FormControl>
      <FormControl className={classes.formControlMultiline}>
        <label className={classes.multilineLabel}>
          <FormattedMessage id={messages.ebilling} />
        </label>
        <TextField
          id="ebilling"
          name="ebilling"
          inputProps={{
            name: 'ebilling',
            id: 'ebilling'
          }}
          onChange={handleChangeTextfield}
          value={eBillingWatch}
          disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
          className={classes.multilineTextField}
          type="string"
          multiline
          variant="filled"
        />
      </FormControl>
      <FormControl className={classes.formControlMultiline}>
        <label className={classes.multilineLabel}>
          <FormattedMessage id={messages.restaurant} />
        </label>
        <TextField
          id="restaurantInfo"
          name="restaurantInfo"
          inputProps={{
            name: 'restaurantInfo',
            id: 'restaurantInfo'
          }}
          onChange={handleChangeTextfield}
          value={restaurantInfoWatch}
          disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
          className={classes.multilineTextField}
          type="string"
          multiline
          variant="filled"
        />
      </FormControl>
      <FormControl className={classes.formControlMultiline}>
        <label className={classes.multilineLabel}>
          <FormattedMessage id={messages.customerDocumentation} />
        </label>
        <div style={{width: "100%"}}>
          <div {...getRootProps({className: classes.dropzone})}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p><FormattedMessage id={messages.dropZoneDrop}/></p> :
                <p><FormattedMessage id={messages.dropZoneDropOrClick}/></p>
            }
          </div>
          {
            rejectedFiles.length > 0 &&
              <Alert style={{marginTop: "10px", marginBottom: "10px"}} variant="danger">
                <FormattedMessage id={messages.rejectedFile}/>
              </Alert>
          }
        <div className={classes.documentationTextField}
             style={{ maxHeight: "100%", float: "right" }}>
          {files && <List dense={true} style={{width: "100%", float: "left"}}>
            {files.map((doc, index) => {
              return <><ListItem key={index}
                               style={{minHeight: 20, justifyContent: "space-between"}}>
                <ListItemText
                  primary={doc.name}>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Authorize allow={"Asiakaspalvelu"}>
                    <IconButton onClick={() => getFile(doc)}>
                        <DownloadIcon/>
                      </IconButton>
                  </Authorize>
                  <Authorize allow={"Myyntipalvelu"}>
                    <IconButton onClick={() => {
                      setFileToRemove(doc);
                      setOpenRemove(true)
                    }} edge="end" aria-label="delete">
                      <DeleteIcon/>
                    </IconButton>
                  </Authorize>
                </ListItemSecondaryAction>
              </ListItem>
              </>
            })}
          </List>
          }
          </div>
        </div>
      </FormControl>
      <AddFileDialog
        data={acceptedFiles}
        addFiles={handleAddFiles}
        classes={classes}
        open={open}
        setOpen={setOpen}
      />
      <RemoveFileDialog
        data={fileToRemove}
        removeFile={removeFile}
        classes={classes}
        open={openRemove}
        setOpen={setOpenRemove}
      />
    </>
  )

}


import {
  List,
  MenuItem
} from '@material-ui/core';
import {useState} from "react";
import EditOfferBase from "../../Reservations/Reservation/FormSections/SendingInfoActions/EditOfferBase";
import useAxios from "use-axios";
import {put} from "axios";
import config from '../../../config';

const offerBaseUrl = `${config.API_PATH}/offers/base`;

export default function OfferBases() {

  const { data: offerBaseData } = useAxios(offerBaseUrl);

  const [editingIndex, setEditingIndex] = useState(-1);

  const save = async (data, content) => {
    data.offerContent = content;
    try {
      await put(`${offerBaseUrl}`, data);
    } catch (error) {

    }
    setEditingIndex(-1);
  };

  return (
      <List>
        { !offerBaseData[editingIndex] ? offerBaseData.map( (offerBase, index) => {
          return <MenuItem key={index} onClick={() => setEditingIndex(index)}>
            { offerBase.name }
          </MenuItem>
        })
        : <EditOfferBase offerValue={offerBaseData[editingIndex]}
                      handleSave={save}
                      setShowEditor={() => setEditingIndex(-1)}/>}
      </List>
    )
}

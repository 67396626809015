import { Suspense } from 'react';
import Spinner from 'Spinner';
import Form from './Form';
import useAxios, { reload } from "use-axios";
import {
  put,
  delete as del,
  post
} from 'axios';
import config from '../../../config';


function EditProduct(props) {
  const productUrl = `${config.API_PATH}/product`;

  const {data} = useAxios(`${productUrl}/${props.match.params.id}`);

  const created = props.location.state ? props.location.state.created : false;

  const save = async (data) => {
    try {
      await put(`${productUrl}`, data);
      reload(productUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const copy = async (data) => {
    try {
      const response = await post(`${productUrl}/clone/${data.id}`);
      props.history.push(`/product/${response.data.id}`);
      reload(productUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const remove = async data => {
    try {
      await del(`${productUrl}/{id}?id=${data.id}`);
      reload(`${productUrl}`);
      props.history.push('/products');
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Suspense fallback={<Spinner/>}>
        <Form data={data}
              save={save}
              created={created}
              remove={remove}
              copy={copy}
        />
      </Suspense>
    </>
  );
}

export default EditProduct;

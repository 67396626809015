import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import messages from 'intl/messages.properties';
import * as PropTypes from "prop-types";
import { post } from 'axios'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  TextField
} from '@material-ui/core';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons/index";
import {useFormContext} from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import config from '../../../../../config';

const offersUrl = `${config.API_PATH}/offers/send`;

export default function SendDialog(props) {
  const { classes, open, setOpen, rowData} = props;

  const { setValue, watch } = useFormContext();

  const contactsWatch = watch("reservationContacts");
  const offersWatch = watch("offers");

  const defaultSender = "myynti@serlachius.fi";

  const defaultCoverLetter = "Hei!\n" +
    "\n" +
    "Kiitämme mielenkiinnosta Serlachius-museoita kohtaan. \n" +
    "\n" +
    "Liitteenä tilausvahvistus sovituista palveluista.\n" +
    "\n" +
    "Liitteenä tarjous / ohjelmaehdotus vierailupäiväänne.\n" +
    "\n" +
    "Liitteenä matkanjohtajan ohjeistus.\n" +
    "Huomioikaa erityisesti kohta:  Isot laukut säilytykseen\n" +
    "\n" +
    "Museovierailun aikana päällysvaatteet ja isot laukut jätetään vaatesäilytystiloihin. Asiakkaiden käyttöön löytyy molemmissa museoissa lukollisia kaappeja. Kätevintä on kuitenkin jättää isot laukut autoon. \n" +
    "\n" +
    "Ilmoitattehan vähintään kolme arkipäivää ennen vierailua\n" +
    "- laskutusosoitteen, mikäli haluatte, että laskutamme palvelut jälkikäteen\n" +
    "- mahdolliset erityisruokavaliot ja allergiat\n" +
    "- vahvistetun henkilömäärän. Kolme päivää ennen vierailua ilmoitettu henkilömäärä on laskutuksen peruste.\n" +
    "\n" +
    "Annan mielelläni lisätietoja ja muokkaan ohjelmaa ryhmälle sopivaksi.\n" +
    "\n" +
    "Ystävällisin terveisin\n" +
    "Serlachius-museot\n" +
    "Myyntipalvelu\n\n\n"
  ;


  const [ coverLetter , setCoverLetter ] = React.useState(defaultCoverLetter);
  const [ emails , setEmails ] = React.useState([]);
  const [ sender , setSender ] = React.useState(defaultSender);
  const [ contactEmails, setContactEmails ] = React.useState([]);

  useEffect(() => {
    setContactEmails([...contactsWatch
      .filter( contact => contact !== undefined && contact.email !== "")
      .map( contact => contact.email)]);
  }, [contactsWatch, setContactEmails]);

  const idWatch = watch("id");

  const oldEmails = [...emails];
  const oldSender = defaultSender;

  function handleClose() {
    setCoverLetter( defaultCoverLetter );
    setEmails( oldEmails );
    setSender( oldSender );
    setOpen(false);
  }

  const sendOffer = async (data) => {
    return await post(`${offersUrl}`, data);
  }

  const handleSending = () => {
    const sentOffer = {...rowData, emails: [...contactEmails, ...emails], sender, coverLetter, offerStatus: "SENT", reservationId: idWatch};
    sendOffer(sentOffer).then((response) => {
      setValue("offers", [...offersWatch.filter(offer => offer !== rowData), response.data]);
    });
    setOpen(false);
  };

  const handleCoverLetterChange = event => {
    setCoverLetter(event.target.value )
  };

  const handleSenderChange = event => {
    setSender(event.target.value )
  };

  const handleEmailChange = index => event => {
    setEmails( emails.map( (email, emailIndex) => {
      if( emailIndex === index )
        return event.target.value;
      else
        return email;
    }))
  };
  const addEmail = () => {
    setEmails([ ...emails, "" ]);
  };

  const removeEmail = index => {
    setEmails( emails.filter( (email, emailIndex ) => emailIndex !== index ) )
  };

  return (
    <Dialog open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg">
      <DialogTitle>
        <FormattedMessage id={messages.sendDialogContentTitle}/>
      </DialogTitle>
      <Alert style={{ margin: "24px" }} variant="danger">
        <FormattedMessage id={messages.sendDialogFormDirty}/>
      </Alert>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 40 }}>
          <FormattedMessage id={messages.sendDialogContent} />
        </DialogContentText>

        <FormControl className={ classes.split60 }>
          <label>
            <FormattedMessage id={messages.sender} />
          </label>
          <TextField id="sender"
                     value={sender}
                     className={classes.textField}
                     style={{margin: "0 0 15px"}}
                     type="email"
                     variant="filled"
                     onChange={handleSenderChange}/>
          <label>
            <FormattedMessage id={messages.coverLetter} />
          </label>
          <TextField id="coverLetter"
                     value={coverLetter}
                     multiline
                     className={classes.multilineTextField}
                     type="string"
                     variant="filled"
                     onChange={handleCoverLetterChange}/>

        </FormControl>

        <FormControl className={ classes.split40 }>
          <label>
            <FormattedMessage id={messages.recipients} />
          </label>
          { contactEmails.map( (email, index) => {
            return <TextField id={email}
                         value={email}
                         key={index}
                         className={classes.textField}
                         type="email"
                         variant="filled"
                         disabled
                         style={{margin: " 0 5px 15px "}}/>
          })}

          { emails.map( (email, index) =>
            <FormControl className={classes.emailFormControl}
                         key={index}>
              <TextField id={email}
                         value={email}
                         className={classes.textField}
                         type="email"
                         variant="filled"
                         onChange={handleEmailChange(index)}
                         style={{margin: " 0 5px 15px "}}/>
              <Button onClick={() => removeEmail(index)}
                      style={{ float: "right" }}>
                <FontAwesomeIcon icon={ faTimes }/>
              </Button>
            </FormControl>
          )}
          <FormControl className={classes.emailFormControl}>
            <Button onClick={() => addEmail()}
                    classes={classes} >
              <FontAwesomeIcon icon={ faPlus }
                               style={{ marginRight: 5 }}/>
              <FormattedMessage id={messages.add} />
            </Button>

          </FormControl>
        </FormControl>

      </DialogContent>

      <Divider/>

      <DialogActions>
        <Button onClick={handleClose}
                color="primary"
                style={{ margin: "5px 10px 5px 5px" }}>
          <FormattedMessage id={messages.cancel}/>
        </Button>
        <Button onClick={handleSending}
                color="primary"
                disabled={ emails === 0 && contactEmails.length === 0 }
                className={classes.greenButton}
                style={{ margin: "5px 10px 5px 5px" }}>
          <FormattedMessage id={messages.send}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}


SendDialog.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

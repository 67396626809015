import { useEffect } from 'react';
import { useSetUser } from '@postinumero/authorization';
import { useKeycloak } from 'react-keycloak';
import config from '../../config';

export default () => {
  const { keycloak } = useKeycloak();
  const setUser = useSetUser();
  useEffect(() => {
    if (keycloak.authenticated) {
      setUser({
        id: keycloak.tokenParsed.sub,
        name:
          keycloak.tokenParsed.name ?? keycloak.tokenParsed.preferred_username,
        roles:
        keycloak.tokenParsed.resource_access[config.KEYCLOAK_CLIENT_ID]
          ?.roles || []
      });
    } else {
      keycloak.login()
    }
  }, [setUser, keycloak]);
};

import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';
import {useKeycloak} from "react-keycloak";
import { Dropdown, DropdownButton } from "react-bootstrap";
import useUser from "@postinumero/authorization/useUser";

export default function Logout() {
  const { keycloak } = useKeycloak();
  const user = useUser();
  return (
    <DropdownButton
      alignRight
      variant="outline-light"
      color="default"
      title={user.name}
    >
      <Dropdown.Item
        as="button"
        onClick={() => {
          keycloak.logout({redirectUri: window.location.origin});
        }}
      >
        <FormattedMessage id={messages.logout} />
      </Dropdown.Item>
    </DropdownButton>
  );
}

import {Box, Button, MenuItem, Paper, Select, TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import reservationTypes from "../../shared/definitions/reservationTypes";
import React, {useEffect} from "react";
import messages from 'intl/messages.properties';
import {get} from "axios";
import moment from "moment";
import { camelCase } from 'lodash';
import useForm from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import config from '../../config';

const searchUrl = `${config.API_PATH}/search`;

function IndexedSearch({setReservations, past, classes}) {

  const [showSearch, setShowSearch] = React.useState(false);
  const [visitingDateStart, setVisitingDateStart] = React.useState(null);
  const [visitingDateEnd, setVisitingDateEnd] = React.useState(null);
  const [reservationType, setReservationType] = React.useState("All");

  const formMethods = useForm({
    mode: 'onBlur',
  });

  const {register, errors, handleSubmit, setValue} = formMethods;

  useEffect(() => {
    register({ name: "visitingDateStart" });
    register({ name: "visitingDateEnd" });
    register({ name: "reservationType" })
  }, [register])

  const handleStartDateChange = date => {
    setVisitingDateStart(date);
    setValue("visitingDateStart", date);
  }

  const handleEndDateChange = date => {
    setVisitingDateEnd(date);
    setValue("visitingDateEnd", date);
  }

  function handleSelectChange(event) {
    setReservationType(event.target.value);
    setValue(event.target.name, event.target.value);
  }

  const onSubmitSearch = async (searchData, event) => {
    event.preventDefault();
    if (past) {
      if (!searchData.visitingDateEnd || moment(searchData.visitingDateEnd).isAfter(moment())) {
        searchData.visitingDateEnd = new Date();
      }
    } else {
      if (!visitingDateStart || moment(searchData.visitingDateStart).isBefore(moment().subtract(1, "months"))) {
        searchData.visitingDateStart = moment().subtract(1, "months").toDate();
      }
    }

    let params = "?";
    params += "reservationName=" + searchData.reservationName + "&";
    if (searchData.visitingDateStart) {
      params += "visitingDateStart=" + moment(searchData.visitingDateStart).format("YYYY-MM-DD") + "&";
    }
    if (searchData.visitingDateEnd) {
      params += "visitingDateEnd=" + moment(searchData.visitingDateEnd).format("YYYY-MM-DD") + "&";

    }
    if (searchData.reservationType && searchData.reservationType !== "All") {
      params += "reservationType=" + searchData.reservationType + "&";
    }
    params += "organization=" + searchData.organization + "&";
    params += "contactFirstname=" + searchData.contactFirstName + "&";
    params += "contactLastname=" + searchData.contactLastName + "&";
    params += "accountableResourceName=" + searchData.accountableResourceName + "&";
    const result = await get(`${searchUrl}/reservationSearch${params}`)
    setReservations(result.data);
  };

  return (
    <Paper style={{padding: "10px", margin: "10px 0", backgroundColor: "#F3F3F3"}}>
      <FormControl className={classes.formControl}>
        <Button onClick={() => setShowSearch(!showSearch)}
                style={{padding: 0}}>
          <FontAwesomeIcon icon={showSearch ? faMinus : faPlus}
                           style={{marginRight: 10}}/>
          <FormattedMessage id={messages.detailedSearch}/>
        </Button>
      </FormControl>
      {
        showSearch &&
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <Box style={{display: "flex", justifyContent: "space-evenly"}}>
              <Box style={{display: "flex", flexDirection: "column", width: "48%"}}>
                <FormControl>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.reservationName}/>
                  </label>
                  <TextField id="reservationName"
                             name="reservationName"
                             inputRef={register}
                             error={errors.reservationName}
                             className={classes.textField}
                             variant="filled"/>
                </FormControl>

                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    minDate={!past ? moment().subtract(1, "months").toDate() : undefined}
                    maxDate={visitingDateEnd !== null ? visitingDateEnd : past ? moment().toDate() : undefined}
                    disableFuture={past}
                    variant="inline"
                    inputVariant="filled"
                    format="DD.MM.YYYY"
                    margin="normal"
                    emptyLabel={messages.any}
                    className={classes.visitingTimedateField}
                    value={visitingDateStart}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <FontAwesomeIcon icon={faMinus}
                                   style={{width: "0.6em", margin: "0 8px"}}/>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    minDate={visitingDateStart !== null ? visitingDateStart : !past ? moment().subtract(1, "months").toDate() : undefined}
                    maxDate={past ? moment().toDate() : undefined}
                    disableFuture={past}
                    variant="inline"
                    inputVariant="filled"
                    format="DD.MM.YYYY"
                    margin="normal"
                    emptyLabel={messages.any}
                    className={classes.visitingTimedateField}
                    value={visitingDateEnd}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </div>
                <FormControl className={classes.formControl}>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.reservationType}/>
                  </label>
                  <Select id="reservationType"
                          name="reservationType"
                          value={reservationType}
                          onChange={handleSelectChange}
                          className={classes.select}
                          variant="filled"
                          inputProps={{
                            name: 'reservationType',
                            id: 'reservationType'
                          }}>
                    <MenuItem value={"All"}>
                      <FormattedMessage id={messages.all}/>
                    </MenuItem>
                    {reservationTypes.map((type, index) =>
                      <MenuItem key={index}
                                value={type}>
                        <FormattedMessage id={messages[camelCase(type)]}/>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box style={{display: "flex", flexDirection: "column", width: "48%"}}>
                <FormControl>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.organizationName}/>
                  </label>
                  <TextField id="organization"
                             name="organization"
                             inputRef={register}
                             error={errors.organization}
                             className={classes.textField}
                             variant="filled"/>
                </FormControl>
                <FormControl>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.contactFirstName}/>
                  </label>
                  <TextField id="contactFirstName"
                             name="contactFirstName"
                             inputRef={register}
                             error={errors.contactFirstName}
                             className={classes.textField}
                             variant="filled"/>
                </FormControl>
                <FormControl>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.contactLastName}/>
                  </label>
                  <TextField id="contactLastName"
                             name="contactLastName"
                             inputRef={register}
                             error={errors.contactLastName}
                             className={classes.textField}
                             variant="filled"/>
                </FormControl>
                <FormControl>
                  <label className={classes.label}>
                    <FormattedMessage id={messages.accountableResourceName}/>
                  </label>
                  <TextField id="accountableResourceName"
                             name="accountableResourceName"
                             inputRef={register}
                             error={errors.accountableResourceName}
                             className={classes.textField}
                             variant="filled"/>
                </FormControl>
              </Box>
            </Box>
            <Box style={{textAlign: "right", margin: "10px"}}>
              <Button variant="contained" color="primary" type="submit"><FormattedMessage id={messages.doSearch}/></Button>
            </Box>
          </form>
        </MuiPickersUtilsProvider>
      }
    </Paper>
  )
}

export default IndexedSearch;

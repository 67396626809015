import React, {useEffect, useRef} from 'react';

import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import useAxios from "use-axios";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Button,
  List
} from "@material-ui/core";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus} from "@fortawesome/free-solid-svg-icons/index";

import programEditorStyle from "../../../../styles/ProgramEditorStyle";
import moment from "moment";
import {useFormContext} from "react-hook-form";
import Authorize from "@postinumero/authorization/Authorize";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import config from '../../../../config';

const useStyles = makeStyles(programEditorStyle);


export default function Program(props) {

  const calendarRef = useRef();

  const { formClasses, openAddNewProgramItem, editProgramItem, checkProductLimitation, selectedDate, setSelectedDate, programItems } = props;
  const [ selectedAccountable, setSelectedAccountable ] = React.useState('');

  const classes = useStyles();

  const { register, watch, setValue } = useFormContext();

  useEffect(() => {
    register({ name: "accountableResources" });
    register({ name: "reactionToReservation" });
  }, [register]);

  const reservationGroupsWatch = watch("reservationGroups");
  const accountableResourcesWatch = watch("accountableResources");
  const visitingTimesWatch = watch("reservationVisitingTimes");
  const reactionToReservationWatch = watch("reactionToReservation");

  const { data: accountables } = useAxios( `${config.API_PATH}/resource/accountable` );

  const customButtons = {};
    visitingTimesWatch.forEach((visitingTime) => {
      const date = moment(visitingTime.visitingDate).format("DD.MM.YYYY");
      customButtons[date] = {
        text: date,
        click: function() {
          let calendarApi = calendarRef.current.getApi();
          calendarApi.gotoDate(visitingTime.visitingDate);
          setSelectedDate(visitingTime.visitingDate)
        }
      }
    });

  if (visitingTimesWatch.findIndex(time => time.visitingDate === selectedDate) === -1) {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(visitingTimesWatch[0].visitingDate);
    setSelectedDate(visitingTimesWatch[0].visitingDate);
  }

  const handleEventClick = (eventClickInfo) => {
    const event = eventClickInfo.event;
    const programItem = {
      id: event.id,
      tempId: event.extendedProps.tempId,
      productName: event.title,
      productDescription: event.extendedProps.productDescription,
      showProduct: event.extendedProps.showProduct,
      productId: event.extendedProps.productId,
      startingTime: event.start,
      endingTime: event.end,
      length: (event.end.getTime() - event.start.getTime()) / 1000 / 60,
      group: parseInt(event.getResources()[0].id),
      productPrice: event.extendedProps.productPrice,
      productPricingInformation: event.extendedProps.productPricingInformation,
      resourcesRequired: event._def.extendedProps.resourcesRequired,
      reservedResources: event.extendedProps.reservedResources
    };

    editProgramItem(programItem);
  };

  const handleEventMove = (eventDropInfo) => {

    const newReservationGroups = [...reservationGroupsWatch];

    let groupId;
    // Find group before event
    if (eventDropInfo.oldResource === null) {
      groupId = parseInt(eventDropInfo.event.getResources()[0].id);
    } else {
      groupId = parseInt(eventDropInfo.oldResource.id);
    }
    const groupIndex = reservationGroupsWatch.findIndex(group => group.id === groupId || group.tempId === groupId);

    const programItemIndex = reservationGroupsWatch[groupIndex].reservationGroupProgramItems.findIndex(programItem =>
       programItem.id === parseInt(eventDropInfo.event.id) ||
       programItem.tempId === parseInt(eventDropInfo.event.extendedProps.tempId)
    );

    const groupProgramItem = newReservationGroups[groupIndex].reservationGroupProgramItems[programItemIndex];
    const startingTime = moment(groupProgramItem.startingTime, "HH:mm");
    groupProgramItem.startingTime = startingTime.add(eventDropInfo.delta, "ms").format("HH:mm");

    if (eventDropInfo.newResource !== null) {
      newReservationGroups[groupIndex].reservationGroupProgramItems.splice(programItemIndex, 1);
      const newGroupIndex = newReservationGroups.findIndex(group => group.id === parseInt(eventDropInfo.newResource.id) || group.tempId === parseInt(eventDropInfo.newResource.id));
      newReservationGroups[newGroupIndex].reservationGroupProgramItems.push(groupProgramItem);
    }

    setValue("reservationGroups", newReservationGroups);
  };

  const handleEventResize = (eventResizeInfo) => {
    const newReservationGroups = [...reservationGroupsWatch];
    const groupId = parseInt(eventResizeInfo.event.getResources()[0].id);
    const groupIndex = reservationGroupsWatch.findIndex(group => group.id === groupId || group.tempId === groupId);
    const eventId = parseInt(eventResizeInfo.event.id);
    const eventTempId = parseInt(eventResizeInfo.event.extendedProps.tempId);
    const programItemIndex = reservationGroupsWatch[groupIndex].reservationGroupProgramItems.findIndex(programItem =>
      programItem.id === eventId ||
      programItem.tempId === eventTempId
    );
    const programItem = Object.assign({}, reservationGroupsWatch[groupIndex].reservationGroupProgramItems[programItemIndex]);
    programItem.length = parseInt(programItem.length) + (eventResizeInfo.endDelta.milliseconds/1000/60)
    newReservationGroups[groupIndex].reservationGroupProgramItems[programItemIndex] = programItem;
    setValue("reservationGroups", newReservationGroups);
  };

  const handleSelectChange = event => {
    setSelectedAccountable(event.target.value);
  };

  const selectAccountable = () => {
    setValue("accountableResources", [...accountableResourcesWatch, selectedAccountable.id]);
  };

  const removeAccountable = removeValue => {
    setValue("accountableResources", accountableResourcesWatch.filter(accountable =>
      accountable !== removeValue
    ));
  };

  const handleItemDateChange = date => {
    const dateValue = moment(date);
    setValue("reactionToReservation", dateValue.format("YYYY-MM-DD"));
  };


  const [, update] = React.useState();
  useEffect(() => {
    update();
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.render();
    }
  }, [programItems])
  useEffect(() => {
    update();
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.render();
    }
  }, [customButtons])

  return ( <>
    <div className={classes.programEditorContent}>
      <div className={classes.programContent}>
        <FullCalendar
          ref={calendarRef}
          defaultView="resourceTimeGridDay"
          defaultDate={visitingTimesWatch[0].visitingDate}
          plugins={[ resourceTimeGridPlugin, interactionPlugin ]}
          locale={"fi"}
          height={800}
          customButtons={customButtons}
          header={{
            // Buttons for each unique visitingTime.visitingDate
            left: [...new Set(visitingTimesWatch.map(value => moment(value.visitingDate).format("DD.MM.YYYY")))].join(","),
            center: '',
            right: 'title'
          }}
          rerenderDelay={100}
          events={programItems}
          resources={reservationGroupsWatch.map((group, index) => (
            {
              title: "Ryhmä " + (index+1),
              id: group.id || group.tempId,
              color: group.groupColorHex,
              groupSize: group.groupSize
            }))}
          timezone='local'
          eventClick={handleEventClick}
          eventDrop={handleEventMove}
          eventResize={handleEventResize}
          snapDuration={"00:15"}
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
          eventRender={ function( info ) {
            info.el.style.border = "solid 1px #b4b4b4";
            info.el.style.borderTop = `solid 7px ${info.event._def.ui.borderColor}`;
          }}
          eventBorderColor={'color'}
          eventBackgroundColor={'white'}
          displayEventTime={true}
          displayEventEnd={true}
          slotWidth={"auto"}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            hour12: false
          }}
          allDaySlot={false}
          buttonText={{today:'Tänään'}}
          // scrollTime={"08:00:00"}
          minTime={"00:00:00"}
          maxTime={"24:00:00"}
          eventAllow={(dropInfo, draggedEvent) => {
              const itemMoment = moment(dropInfo.start);
              return checkProductLimitation(
                draggedEvent.extendedProps.productId,
                draggedEvent.extendedProps.tempId || draggedEvent.id,
                itemMoment.format("YYYY-MM-DD"),
                itemMoment.format("HH:mm"),
                moment(dropInfo.end).format("HH:mm"));
            }}
          slotEventOverlap={false}
          editable={useAuthorize({ allow: "Asiakaspalvelu" })}
          eventStartEditable={useAuthorize({ allow: "Asiakaspalvelu" })}
          eventResourceEditable={useAuthorize({ allow: "Asiakaspalvelu" })}
          eventDurationEditable={useAuthorize({ allow: "Asiakaspalvelu" })}
          eventResizableFromStart={false}
        />
        <Authorize allow={"Asiakaspalvelu"}>
          <Button variant="contained" className={classes.greenButton} onClick={openAddNewProgramItem}>
            <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/><FormattedMessage id={messages.addProgramItem}/>
          </Button>
        </Authorize>
      </div>

      <div>
        <FormControl className={formClasses.formControl}>
          <label className={formClasses.label}
                 style={{ width: 170, minWidth: 170 }}>
            <FormattedMessage id={messages.reactionToReservation}/>
          </label>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
            variant="inline"
            inputVariant="filled"
            format="DD.MM.YYYY"
            margin="normal"
            className={classes.dateField}
            value={reactionToReservationWatch}
            onChange={handleItemDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>
        <FormControl className={formClasses.formControlMultiline}>

          <label className={formClasses.label}
                 style={{ width: 170, minWidth: 170 }}>
            <FormattedMessage id={messages.accountables}/>
          </label>

          <div className={classes.split40}
               style={{ padding: 0 }}>
            <Select value={selectedAccountable}
                    disabled={!useAuthorize({ allow: "Asiakaspalvelu" })}
                    className={formClasses.select}
                    variant="filled"
                    onChange={handleSelectChange}>
              {accountables.filter(resource => !accountableResourcesWatch.includes(resource.id)).map((resource, index) =>
                <MenuItem key={index}
                          value={resource}>
                  {resource.fullName}
                </MenuItem>
              )}
            </Select>
            <Button variant="contained"
                    disabled={!useAuthorize({ allow: "Asiakaspalvelu" }) || !selectedAccountable || accountableResourcesWatch.includes(selectedAccountable.id)}
                    className={classes.button}
                    onClick={selectAccountable}>
              <FormattedMessage id={messages.choose} />
            </Button>
          </div>
          <div className={classes.split60}>
            <List>
              { accountableResourcesWatch && accountableResourcesWatch !== 0 &&
              accountableResourcesWatch.map( (accountableId, index) => {
                const currAccountable = accountables.find(accountable => accountable.id === accountableId)
                if (!currAccountable) {
                  return null;
                }
                return <MenuItem key={index}
                                 style={{ minHeight: 20, justifyContent: "space-between" }}>
                  { currAccountable.fullName }
                  <Authorize allow={"Asiakaspalvelu"}>
                    <Button onClick={() => removeAccountable(accountableId)}
                            className={classes.button}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                  </Authorize>
                </MenuItem>
              })}
            </List>
          </div>
        </FormControl>
      </div>
    </div>
    </>
  )
}

import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';

import TabPanel from '../../../../shared/TabPanel';

import {AppBar, Box, Button, FormControl, Tab, Tabs, TextField} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faParking, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons/index";
import {useFormContext} from "react-hook-form";
import Authorize from "@postinumero/authorization/Authorize";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import Autocomplete from "@material-ui/lab/Autocomplete";
import pipedriveLogo from "../../../../images/pipedrive-logo.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../../config';

export default function ReservationContacts(props){

  const isAsiakaspalvelu = useAuthorize({ allow: "Asiakaspalvelu" });

  const { classes, errors, validateContacts } = props;
  const { register, setValue, watch } = useFormContext();
  const [ activeTab, setActiveTab ] = React.useState(0);
  const [ contactsLoading, setContactsLoading ] = React.useState(true);
  const [ availableContacts, setAvailableContacts ] = React.useState([]);
  const [ activeContactData, setActiveContactData ] = React.useState([]);

  useEffect(() => {
    if (!contactsLoading) {
      return;
    }
    (async () => {
      const response = await fetch(`${config.API_PATH}/reservationContact/active`);
      const data = await response.json();
      setActiveContactData(data);
      setContactsLoading(false);
    })();
  }, [contactsLoading]);

  const emptyContact = {
    organization: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumbers: [
      ""
    ],
    email: "",
    address: "",
    postalNumber: "",
    postOffice: "",
    reservationCategory: "VARAAJA",
    reservationRole: ""
  };

  const filterAvailableContacts = () => {
    setAvailableContacts(
      activeContactData.filter(
        contact => contacts.findIndex(
          selectedContact => selectedContact.id === contact.id
        ) < 0).map(contact => ({
          id: contact.id,
          pipedriveId: contact.pipedriveId,
          firstName: contact.firstName,
          lastName: contact.lastName,
          organization: contact.organization
        }
      )));
  };

  useEffect(() => {
    register({ name: "reservationContacts", type: 'custom' });
  }, [register]);

  useEffect(() => {
    if (Object.entries(errors).length) {
      setActiveTab(parseInt(Object.keys(errors)[0]));
    }
  }, [errors]);

  // Separate contacts hook for handling typing changes and skipping filtering when possible
  const reservationContactsWatch = watch("reservationContacts");
  const [contacts, setContacts] = React.useState(reservationContactsWatch);

  useEffect(() => {
    setAvailableContacts(
      activeContactData.filter(
        contact => contacts.findIndex(
          selectedContact => selectedContact.id === contact.id
        ) < 0).map(contact => ({
          id: contact.id,
          pipedriveId: contact.pipedriveId,
          firstName: contact.firstName,
          lastName: contact.lastName,
          organization: contact.organization
        }
      )));
  }, [activeContactData, contacts, setAvailableContacts]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function handleTabChange(event, newValue) {
    setActiveTab(newValue);
  }

  function handleContactChange(event, contactIndex) {
    const newContacts = [...contacts];
    newContacts[contactIndex][event.target.name] = event.target.value;
    setContacts(newContacts);
  }

  function handleValidateContacts() {
    validateContacts();
    setValue("reservationContacts", contacts);
  }

  function handlePhoneNumberChange(event, contactIndex, numberIndex) {
    const newContacts = [...contacts];
    newContacts[contactIndex].phoneNumbers[numberIndex] = event.target.value;
    setContacts([...newContacts]);
  }

  function handleContactAdd(contact) {
    setContacts([...contacts, contact || emptyContact]);
    setValue("reservationContacts", [...contacts, contact || emptyContact]);
    setActiveTab(contacts.length);
    filterAvailableContacts();
  }

  function handleContactSelect(newContact) {
    const newReservationContacts = [...reservationContactsWatch.map((contact, index) => {
      return index === activeTab ? newContact : contact;
    })];
    setContacts(newReservationContacts);
    setValue("reservationContacts", newReservationContacts);
    filterAvailableContacts();
  }

  function handleAutocompleteChange(event, value) {
    if (value === null) {
      return;
    }
    handleContactSelect(activeContactData.find((contact) => contact.id === value.id));
  }

  const handleContactRemove = contactIndex => {
    const newContacts = [...reservationContactsWatch.filter((contact, index) => index !== contactIndex)];
    setContacts(newContacts);
    setValue("reservationContacts", newContacts);
    setActiveTab(0);
    filterAvailableContacts();
  };

  function addPhoneNumber(index) {
    const newContacts = [...reservationContactsWatch];
    newContacts[index].phoneNumbers.push("");
    setContacts(newContacts);
  }

  const handlePhoneNumberRemove = ( itemIndex, contactIndex ) => {
    let newContacts = [...reservationContactsWatch];
    newContacts[contactIndex].phoneNumbers = newContacts[contactIndex].phoneNumbers.filter( (group, index) => index !== itemIndex);
    setContacts(newContacts);
  };

  return (
    <>
      <AppBar position="static"
              className={classes.appBar}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10
              }}>
        <Tabs value={activeTab}
              onChange={handleTabChange}
              aria-label="">
          { contacts.map( (contact, index )  =>
            <Tab label={<FormattedMessage id={messages.person}/>}
                 key={index}
                 className={classes.appBarTab}
                 {...a11yProps(index)}
            />)
          }
        </Tabs>
        <Authorize allow={"Asiakaspalvelu"}>
          { contacts.length < 4 &&
            <Button onClick={ () => handleContactAdd() }
                    style={{ marginRight: 5, color: "white" }}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
              <FormattedMessage id={messages.addNewPerson} />
            </Button>
          }
        </Authorize>
      </AppBar>
      { contacts.map( (contact, index) =>

        <TabPanel value={activeTab}
                  key={index}
                  index={index}
                  className={classes.tabPanel}
                  style={{
                    paddingTop: 10
                  }}
                  onBlur={handleValidateContacts}>

          <Authorize allow={"Asiakaspalvelu"}>
            <FormControl>
              <Autocomplete
                value={undefined}
                loading={contactsLoading}
                loadingText={messages.loadingText}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Etsi henkilö"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {contactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                clearOnEscape={true}
                getOptionLabel={contact => contact.firstName + " " + contact.lastName}
                options={availableContacts}
                renderOption={option => (
                  <div className={classes.autoCompleteRow}>
                    <div className={classes.autoCompleteRowName}>{option.firstName} {option.lastName}<br/>
                      {option.organization}
                    </div>
                    <div className={classes.autoCompleteRowIcon}>
                      { option.pipedriveId !== null && option.pipedriveId !== undefined && <FontAwesomeIcon icon={faParking}
                                       style={{ width: 25, height: "100%", float: "right" }} />}
                    </div>
                  </div>)
                }
                autoHighlight
                onChange={handleAutocompleteChange}
              />
            </FormControl>
            <div style={{float: "right"}}>
            {

              contact.pipedriveId !== null && contact.pipedriveId !== undefined ?
                <div style={{float: "left"}}>
                  <img src={pipedriveLogo} alt="pipedriveLogo" style={{ width: "25px", height: "100%" }}/>
                </div>
                : contact.id === null || contact.pipedriveId === undefined ?
                <div style={{float: "left"}}>
                  <FormattedMessage id={messages.creatingNewContact}/>
                </div> : null
            }
            { contacts.length > 1 ?
              <Button onClick={() => handleContactRemove(index)}
                      style={{ float: "right" }}>
                <FontAwesomeIcon icon={ faTimes }/>
              </Button>
              : null }
            </div>
          </Authorize>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.organizationName}/>*
            </label>
            <TextField
              id="organization"
              name="organization"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              value={contact.organization || ""}
              error={errors[index]?.includes("organization")}
              className={classes.textField}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.firstName}/>*
            </label>
            <TextField
              id="firstName"
              name="firstName"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              value={contact.firstName || ""}
              error={errors[index]?.includes("firstName")}
              className={classes.textField}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.lastName}/>*
            </label>
            <TextField
              id="lastName"
              name="lastName"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              value={contact.lastName || ""}
              error={errors[index]?.includes("lastName")}
              className={classes.textField}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.jobTitle} />
            </label>
            <TextField
              id="jobTitle"
              name="jobTitle"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              className={classes.textField}
              value={contact.jobTitle || ""}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>


          <FormControl className={classes.formControl}
                       style={{ alignItems: "baseline" }}>

            <label className={classes.label}>
              <FormattedMessage id={messages.phoneNumber}/>
            </label>
            <div style={{ flexDirection: "column" }}>
            { contact.phoneNumbers.map( (number, numberIndex) =>
              <Box key={numberIndex}
                   style={{ padding: 0 }}>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
                  value={number || ""}
                  style={{ marginBottom:  10 }}
                  className={classes.numberField}
                  onChange={(event) => handlePhoneNumberChange(event, index, numberIndex)}
                  variant="filled"/>

                { isAsiakaspalvelu && contact.pipedriveId === null && contact.phoneNumbers.length > 1 ?
                  <Button onClick={() => handlePhoneNumberRemove(numberIndex, index)}
                          style={{ minWidth: 14, padding: "8px 5px" }}>
                    <FontAwesomeIcon icon={ faTimes }/>
                  </Button>
                  : ''
                }
              </Box>
            )}
            </div>
          </FormControl>

          <Authorize allow={"Asiakaspalvelu"}>
            { contact.pipedriveId === null &&
              <FormControl className={classes.reservationChildFields}>
                <Button onClick={() => addPhoneNumber(index)}
                        style={{padding: 0, fontSize: "0.85em"}}>
                  <FontAwesomeIcon icon={faPlus}
                                   style={{margin: "0 7px 0 5px"}}/>
                  <FormattedMessage id={messages.addPhoneNumber}/>
                </Button>
              </FormControl>
            }
          </Authorize>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.email} />*
            </label>
            <TextField
              id="email"
              name="email"
              type="email"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              className={classes.textField}
              value={contact.email || ""}
              error={errors[index]?.includes("email")}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.homeAddress}/>
            </label>
            <TextField
              id="address"
              name="address"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              value={contact.address || ""}
              className={classes.textField}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.postalNumber}/>
            </label>
            <TextField
              id="postalCode"
              name="postalCode"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              value={contact.postalCode || ""}
              className={classes.numberField}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"
              inputProps={{
                maxLength: "5"
              }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>
              <FormattedMessage id={messages.postOffice} />
            </label>
            <TextField
              id="postOffice"
              name="postOffice"
              disabled={!isAsiakaspalvelu || (contact.pipedriveId !== undefined && contact.pipedriveId !== null)}
              className={classes.textField}
              value={contact.postOffice || ""}
              onChange={(event) => handleContactChange(event, index)}
              variant="filled"/>
          </FormControl>


        </TabPanel>

      )}
    </>
  )
}

import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import messages from 'intl/messages.properties';
import {isEmpty} from 'lodash';
import useForm, {FormContext} from "react-hook-form";
import {camelCase} from "lodash";
import resourceRoles from "../../../shared/definitions/resourceRoles";

import Qualifications from "./Qualifications";
import formStyle from '../../../styles/FormStyle';
import CustomSnackbar from "../../../shared/CustomSnackbar";
import TabPanel from "../../../shared/TabPanel";
import FormActionButtons from "../../../shared/FormActionButtons";

import {
  makeStyles,
  Paper,
  Button,
  FormControl,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell, Select, MenuItem,
} from '@material-ui/core'

import {Alert} from "react-bootstrap";
import useAxios, {reload} from "use-axios";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import Authorize from "@postinumero/authorization/Authorize";
import config from '../../../config';


const productUrl = `${config.API_PATH}/product`;
const useStyles = makeStyles(formStyle);

const EMAIL_PATTERN = /^[A-Za-z0-9+_.-]+@(.+)$/;

function Form(props) {
  const classes = useStyles();


  const defaultValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    accountable: false,
    additionalInfo: "",
    role: "MUU",
    productQualifications: []
  };

  const {data: resourceData = defaultValues, created = false} = props;

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: resourceData
  });

  const {clearError, errors, register, handleSubmit, setValue, watch, reset} = formMethods;

  const [disabled, setDisabled] = React.useState(false);
  const [success, setSuccess] = React.useState(created);
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [conflictError, setConflictError] = React.useState(false);
  const [showQualificationsEditor, setShowQualificationsEditor] = React.useState(false);

  const {data} = useAxios(productUrl);
  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    register({name: "id"});
    register({name: "role"}, { required: messages.resourceRoleRequired });
    register({name: "accountable"});
    register({name: "productQualifications"})
  }, [register]);

  const dataWatch = watch();
  const roleWatch = watch("role");
  const accountableWatch = watch("accountable");
  const selectedQualifications = watch("productQualifications");

  useEffect(() => {
    const interval = setInterval(() => reload(productUrl), 30000);
    return () => clearInterval(interval);
  }, []);

  function handleClose(qualifications) {
    setShowQualificationsEditor(false);
    if (qualifications) {
      setValue("productQualifications", qualifications)
    }
  }

  function handleClickOpen() {
    setShowQualificationsEditor(true);
  }


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function handleTabChange(event, newValue) {
    setActiveTab(newValue);
  }

  const handleCheckChange = event => {
    setValue(event.target.name, event.target.checked);
  };

  const closeSnackbar = (event, reason) => {
    // Do not close snackbars on blur
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  function handleRoleChange(event) {
    setValue(event.target.name, event.target.value);
    clearError("role");
  }

  const onSubmit = async (resourceData, event) => {
    setDisabled(true)
    event.preventDefault();
    if (Object.entries(errors).length > 0) {
      setDisabled(false);
      return;
    }

    const resp = await props.save(resourceData)
    if (resp) {
      if (resp.response && resp.response.status === 409) {
        setConflictError(true);
        setError(true);
        setSuccess(false);
        setDisabled(false);
        return;
      }
      setDisabled(false);
      setSuccess(true);
      setError(false);
    } else {
      setDisabled(false)
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <FormContext {...formMethods} >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <CustomSnackbar
          open={success}
          onClose={closeSnackbar}
          type="success"
          message={<FormattedMessage id={messages.saveSuccess}/>}
        />
        <CustomSnackbar
          open={error}
          onClose={closeSnackbar}
          type="error"
          message={<FormattedMessage id={messages.saveError}/>}
        />
        <CustomSnackbar
          open={copySuccess}
          onClose={closeSnackbar}
          type="success"
          message={<FormattedMessage id={messages.copySuccess}/>}
        />
        <FormActionButtons allow="Myyntipalvelu"
                           disabled={disabled}
                           values={dataWatch}
                           classes={classes}
                           remove={props.remove}
                           //copy={props.copy}
                           reset={reset}
                           setCopySuccess={setCopySuccess}
        />

        {conflictError && <Alert variant="danger">
          <FormattedMessage id={messages.uniqueResourceError}/>
        </Alert>}
        <Paper className={classes.paper}>
          {!isEmpty(errors) && Object.values(errors).map((error, index) => {
              return <Alert key={index} variant="danger">
                <FormattedMessage id={error.message}/>
              </Alert>
            }
          )}

          <div className={classes.root}>
            <AppBar position="static"
                    className={classes.appBar}>
              <Tabs value={activeTab}
                    onChange={handleTabChange}
                    aria-label="">
                <Tab label={<FormattedMessage id={messages.basics}/>}
                     className={classes.appBarTab}
                     {...a11yProps(0)} />
                <Tab label={<FormattedMessage id={messages.qualification}/>}
                     {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <fieldset disabled={!useAuthorize({ allow: "Myyntipalvelu" })}>
            <TabPanel value={activeTab}
                      index={0}
                      className={classes.tabPanel}>
              <div className={classes.content}>
                <div className={classes.split}>
                  <FormControl className={classes.formControl}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.fullName}/>
                      *
                    </label>
                    <TextField
                      id="fullName"
                      name="fullName"
                      className={classes.textField}
                      //onChange={handleChange('fullName')}
                      variant="filled"
                      inputRef={register({
                        required: messages.resourceNameRequired
                      })}
                      error={errors.fullName}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.role}/>
                      *
                    </label>
                    <Select id="role"
                            name="role"
                            value={roleWatch || ""}
                            onChange={handleRoleChange}
                            error={errors.role}
                            className={classes.select}
                            variant="filled"
                            inputProps={{
                              name: 'role',
                              id: 'role'
                            }}>
                      {resourceRoles.map( (role, index) =>
                        <MenuItem key={index}
                                  value={role}>
                          <FormattedMessage id={messages[camelCase(role)]} />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.phoneNumber}/>
                    </label>
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      inputRef={register}
                      className={classes.numberField}
                      variant="filled"
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.email}/>
                      *
                    </label>
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      className={classes.textField}
                      variant="filled"
                      error={errors.email}
                      inputRef={register({
                        pattern: {
                          value: EMAIL_PATTERN,
                          message: messages.resourceValidEmail
                        }
                      })}
                    />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.accountable}/>
                    </label>
                    <Checkbox id='accountable'
                              name='accountable'
                              checked={accountableWatch}
                              onChange={handleCheckChange}/>
                  </FormControl>
                </div>

                <div className={classes.split}>
                  <FormControl className={classes.formControlMultiline}>
                    <label className={classes.label}>
                      <FormattedMessage id={messages.additionalInfo}/>
                    </label>
                    <TextField
                      id="additionalInfo"
                      name="additionalInfo"
                      inputRef={register}
                      className={classes.textAreaField}
                      type="string"
                      multiline
                      rows="4"
                      variant="filled"
                      style={{minHeight: 100}}
                    />
                  </FormControl>
                </div>

              </div>

            </TabPanel>
            <TabPanel value={activeTab}
                      index={1}
                      className={classes.tabPanel}>

              <Table aria-labelledby="tableTitle"
              >
                <TableHead>
                  <tr>
                    <th style={{padding: 10}}>
                      <FormattedMessage id={messages.product}/>
                    </th>
                    <th>
                      <FormattedMessage id={messages.languages}/>
                    </th>
                  </tr>
                </TableHead>
                <TableBody className={classes.qualificationList}>
                  {selectedQualifications.map((qualification, index) => {

                    let product = Object.values(data).filter(product => product.id === qualification.productId)[0];

                    if (!product) {
                      return null;
                    }

                    return <TableRow key={index}
                                     hover
                                     className={classes.qualificationItem}>
                      <TableCell>
                        {product.name}
                      </TableCell>
                      <TableCell className={classes.qualificationLanguages}>
                        {qualification.languages.map((language, index) => {
                          return <FormattedMessage id={messages[camelCase(language)]}
                                                   key={index} style={{marginRight: 10}}/>
                        })}
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>

              </Table>

              <Authorize allow={"Myyntipalvelu"}>
                <Button variant="contained"
                        onClick={handleClickOpen}
                        className={classes.editQualificationsButton}>
                  <FormattedMessage id={messages.editQualifications}/>
                </Button>
              </Authorize>
              <Qualifications selectedValues={selectedQualifications}
                              open={showQualificationsEditor}
                              onClose={handleClose}/>
            </TabPanel>
            </fieldset>
          </div>

        </Paper>
        <FormActionButtons allow="Myyntipalvelu"
                           disabled={disabled}
                           values={dataWatch}
                           classes={classes}
                           remove={props.remove}
                           //copy={props.copy}
        />
      </form>
    </FormContext>
  );
}

export default Form;


// NOTE: deployment configs via `gostaRuntimeConfig` !
const defaultConfig = {
  API_PATH: process.env.API_PATH,
  KEYCLOAK_URL: process.env.KEYCLOAK_URL,
  KEYCLOAK_REALM: process.env.KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID: process.env.KEYCLOAK_CLIENT_ID,
};

let runtimeConfig = {};
const runtimeConfigFn = window['gostaRuntimeConfig'];
if (typeof runtimeConfigFn === "function") {
  runtimeConfig = runtimeConfigFn() || {};
}

const config = {...defaultConfig, ...runtimeConfig};
export default config;


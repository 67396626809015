import React, {useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'intl/messages.properties';

import TableActions from './TableActions';

import {useFormContext} from "react-hook-form";

import {
  FormControl,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableHead
} from '@material-ui/core';
import moment from "moment";
import Authorize from "@postinumero/authorization/Authorize";
import useAuthorize from "@postinumero/authorization/useAuthorize";
import useAxios from "use-axios";
import {get} from "axios";
import config from '../../../../config';

const offerBaseUrl = `${config.API_PATH}/offers/base`;
const offerUrl = `${config.API_PATH}/offers`;

export default function SendingInfo(props) {
  const { classes, setShowEditor, setOfferValue } = props;

  const { register, setValue, watch } = useFormContext();

  const { data: offerBaseData } = useAxios(offerBaseUrl);

  const [ selectedValue, setSelectedValue ] = React.useState(offerBaseData[0]);
  const [page, setPage] = React.useState(0);
  const [sentPage, setSentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sentRowsPerPage, setSentRowsPerPage] = React.useState(10);

  const [ offers, setOffers ] = React.useState([]);
  const [ sentOffers, setSentOffers ] = React.useState([]);
  const [ offersEmptyRows, setOffersEmptyRows ] = React.useState([]);
  const [ sentOffersEmptyRows, setSentOffersEmptyRows ] = React.useState([]);


  useEffect(() => {
    register({ name: "offers" });
  }, [register]);

  const offersWatch = watch("offers");
  const idWatch = watch("id");

  const handleSelectChange = event => {
    setSelectedValue(event.target.value)
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleSentChangePage(event, newPage) {
    setSentPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleSentChangeRowsPerPage(event) {
    setSentRowsPerPage(+event.target.value);
    setSentPage(0);
  }

  const addToList = () => {
    const newOffer = {
      name: selectedValue.name,
      offerContent: selectedValue.offerContent,
      // TODO: const from consts
      offerStatus: "DRAFT",
      link: undefined
    };

    setValue("offers", [...offersWatch, newOffer]);
  };

  const getFile = (row) => {
    get(`${offerUrl}/${idWatch}/${row.id}`, {
      responseType: "blob"
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers["content-disposition"].match("filename=([^;]*).*$")[1]);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    setOffers(offersWatch.filter(offer => offer.offerStatus === "DRAFT"));
    setSentOffers(offersWatch.filter(offer => offer.offerStatus === "SENT"));
  }, [setOffers, setSentOffers, offersWatch])

  useEffect(() => {
    setOffersEmptyRows(rowsPerPage - Math.min(rowsPerPage, offers.length - page * rowsPerPage));
    setSentOffersEmptyRows(sentRowsPerPage - Math.min(sentRowsPerPage, sentOffers.length - page * sentRowsPerPage));
  }, [setOffersEmptyRows, setSentOffersEmptyRows, rowsPerPage, sentRowsPerPage, offers, sentOffers, page]);

  return (<>
      <fieldset disabled={!useAuthorize({ allow: "Myyntipalvelu" })}>
      <FormControl className={classes.formControl}
                   style={{ marginBottom: 20 }}>
        <label className={classes.label}>
          <FormattedMessage id={messages.offerBases}/>
        </label>
        <Select id="offerBases"
                name="offerBases"
                disabled={!useAuthorize({ allow: "Myyntipalvelu" })}
                value={selectedValue}
                onChange={handleSelectChange}
                className={classes.select}
                variant="filled"
                style={{ minWidth: 180 }}
                inputProps={{
                  name: 'offerBases',
                  id: 'offerBases'
                }}>
          { offerBaseData.map( (type, index) =>
            <MenuItem key={index}
                      value={type}>
              {type.name}
            </MenuItem>
          )}
        </Select>
        <Authorize allow={"Myyntipalvelu"}>
          <Button variant="contained"
                  disabled={!selectedValue}
                  className={classes.button}
                  style={{ margin: " 0 0 0 10px" }}
                  onClick={addToList}>
              <FormattedMessage id={messages.choose} />
          </Button>
        </Authorize>
      </FormControl>
      </fieldset>

    <div className={classes.tableWrapper}
         style={{marginTop: 20}}>
      <label style={{margin: "20px 0 10px"}}>
        <FormattedMessage id={messages.editableBases} />
      </label>

      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id={messages.dateAbbr} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={messages.offer} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={messages.actions} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { offers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell className={classes.tableCell}>
                    {moment(row.updatedTimestamp).format("DD.MM.YYYY") || ''}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TableActions rowData={row}
                                  setOfferValue={setOfferValue}
                                  setShowEditor={setShowEditor}
                                  classes={classes}/>
                  </TableCell>
                </TableRow>
              );
            })}
          {offersEmptyRows > 0 && (
            <TableRow style={{ height: 35 * offersEmptyRows }}>
              <TableCell colSpan={6}/>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
    <TablePagination
      className={classes.pagination}
      rowsPerPageOptions={[10]}
      rowsPerPage={10}
      component="div"
      count={offers.length}
      labelRowsPerPage = {
        <FormattedMessage id={messages.rowsPerPage}/>
      }
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />

      <div className={classes.tableWrapper}
           style={{marginTop: 20}}>
        <FormattedMessage id={messages.sentDocuments} />
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="small"
      >

        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id={messages.dateAbbr} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={messages.offer} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={messages.recipients} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={messages.actions} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { sentOffers
            .slice(sentPage * sentRowsPerPage, sentPage * sentRowsPerPage + sentRowsPerPage)
            .map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                  onClick={() => getFile(row, index)}
                >
                  <TableCell className={classes.tableCell}>
                    {row.updatedTimeStamp}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}
                             style={{ flexDirection: "row" }}>
                    { row.emails && row.emails.map( (email, index) => {
                        return <div key={index} style={{ width: "100%" }}>
                          {email}
                        </div>
                    })}
                  </TableCell>
                  <TableCell className={classes.tableCell}>

                  </TableCell>
                </TableRow>
              );

            })
          }
          {sentOffersEmptyRows > 0 && (
            <TableRow style={{ height: 35 * sentOffersEmptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
    <TablePagination
      className={classes.pagination}
      rowsPerPageOptions={[10]}
      rowsPerPage={10}
      component="div"
      count={sentOffers.length}
      labelRowsPerPage = {<FormattedMessage id={messages.rowsPerPage}/>}
      page={sentPage}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleSentChangePage}
      onChangeRowsPerPage={handleSentChangeRowsPerPage}
    />

    </>
  )
}
